.grapesjs-fonts select {
  background-color: #352d2f;
  color: #b9a5a6;
  padding: 5px;
  border: none;
}
.grapesjs-fonts input,
.grapesjs-fonts button {
  background-color: #352d2f;
  color: #b9a5a6;
  padding: 5px;
  border: none;
}
.grapesjs-fonts .silex-bar {
  display: flex;
}
.grapesjs-fonts .silex-bar > * {
  margin: 5px;
}

.grapesjs-fonts button {
  padding: 5px;
  margin: 5px;
  padding-top: 8px;
}
.grapesjs-fonts .silex-list__item__footer {
  display: flex;
  justify-content: flex-end;
}

.panel__top {
	padding: 0;
	width: 100%;
	display: flex;
	position: initial;
	justify-content: center;
	justify-content: space-between;
  }
  .panel__basic-actions {
	position: initial;
  }